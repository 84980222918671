
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './config/routesConfig';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18/i18n';
import { initdatadogRum, setDatadogUserDetails } from './utils/dataDog';


initdatadogRum();
setDatadogUserDetails({ displayName: "temp_user" })
function App() {
  return (
    <div>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router}>
        </RouterProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;
